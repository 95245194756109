import $ from 'jquery'
import { gsap, Power2 } from "gsap3:";
import { ScrollTrigger } from "gsap3:/ScrollTrigger.js";
import globals from './globals'
import anime from 'animejs'

document.addEventListener("DOMContentLoaded", function () {
    gsap.registerPlugin(ScrollTrigger)

    let tl = gsap.timeline({
        // yes, we can add it to an entire timeline!
        scrollTrigger: {
            trigger: '.product-shop-callout',
            start: 'top center',
            end:  '+=200px',
            scrub: true,
        }
    })

    tl.fromTo('.detail-select',
        {opacity: '0', x: '30%'},
        {opacity: '1', x: '0', duration: 400}
    )

    if ($('.alt-product').hasClass('product-detail-versions')) {

        var rackOut,
            rackIndex,
            versionActive,
            versionActive,
            optionClicked = false,
            versionClicked = false

        // Set Price to first version
        var price = $('#version-1').attr('data-price')
        $('#price-desktop').html(price)
        $('#price-mobile').html(price)
        $('.product-price').html(price)

        // Versions Control
        $('.version-control').each((index, obj) => {

            obj.addEventListener('click', (e) => {
                e.preventDefault()

                // Set version active by index
                if (index === 0) {
                    versionActive = 1
                } else if (index === 1) {
                    versionActive = 2
                } else if (index === 2) {
                    versionActive = 3
                }

                // Remove versions controls active
                removeVersionControlActive()
                // Active versions control state
                $('#version-control-' + versionActive).addClass('version-control-active')

                // Remove versions active
                removeVersionsActive()
                // Add version active
                $('#version-' + versionActive).addClass('version-active')

                // Remove all multiple details
                removeDetailsActive()
                $('.details-version-' + versionActive).addClass('details-active')

                // Version Price
                var price = $('#version-' + versionActive).attr('data-price')
                $('#price-desktop').html(price)
                $('#price-mobile').html(price)
                $('.product-price').html(price)

                // Fade in
                anime({
                    targets: '#version-' + versionActive,
                    opacity: [0, 1],
                    easing: 'easeInOutQuad'
                })

                // Remove racks active
                removeRacksActive()
                $('#version-' + versionActive + '-rack-' + 1).addClass('rack-active')
                anime({
                    targets: '#version-' + versionActive + '-rack-1',
                    opacity: [0, 1],
                    easing: 'easeInOutQuad'
                })

                // Remove racks Options active
                removeRacksOptionActive()
                // Add rack option active
                $('#detail-rack-option-' + versionActive).addClass('rack-active')

                versionClicked = true
                // Update color switcher
                colorSwitch()

                // Set module product images
                removeProductImagesActive()
                $('#product-image-1').addClass('option-active')
                $('#split-content-product-image-1').addClass('option-active')
                $('#product-hovers-product-image-1').addClass('option-active')
            })
        })

        function removeVersionControlActive() {
            $('.version-control').each((index, obj) => {
                $(obj).removeClass('version-control-active')
            })
        }

        function removeDetailsActive() {
            $('.details-multiple').each((index, obj) => {
                $(obj).removeClass('details-active')
            })
        }

        function removeVersionsActive() {
            $('.version').each((index, obj) => {
                $(obj).removeClass('version-active')
            })
        }

        function removeRacksOptionActive() {
            $('.rack-option-with-multiple').each((index, obj) => {
                $(obj).removeClass('rack-active')
            })
        }

        function removeRacksActive() {
            $('.rack').each((index, obj) => {
                $(obj).removeClass('rack-active')
            })
        }

        function colorSwitch() {

            if (versionClicked) {
                setFirstTitleActive()
                versionClicked = false
            }
            // Color switcher
            $('.rack-option-with-multiple.rack-active .detail-color-option').each((index, obj) => {
                var rackIndex = index += 1

                $(obj).on('click', (e) => {
                    e.preventDefault()

                    var version = $(obj).attr('data-version')

                    console.log(version)

                    // Update specs product image src
                    specsProductImage.src = document.querySelector('#version-' + version + '-rack-' + rackIndex + ' img').getAttribute('src')

                    removeRacksActive()
                    $('#version-' + version + '-rack-' + rackIndex).addClass('rack-active')

                    hideColorTitles()
                    $(obj).parent('.color-option-container').children('.color-title').addClass('color-title-active')

                    anime({
                        targets: '#version-' + version + '-rack-' + rackIndex,
                        opacity: [0, 1],
                        easing: 'easeInOutQuad'
                    })

                    // Switch module product images to match color switcher
                    removeProductImagesActive()
                    $('#product-image-' + rackIndex).addClass('option-active')
                    $('#split-content-product-image-' + rackIndex).addClass('option-active')
                    $('#product-hovers-product-image-' + rackIndex).addClass('option-active')

                    optionClicked = true
                })
            })
        }
        colorSwitch()

        function removeProductImagesActive() {
            $('.product-image').each((index, obj) => {
                $(obj).removeClass('option-active')
            })
        }

        function hideColorTitles() {
            $('.color-title').each((index, obj) => {
                $(obj).removeClass('color-title-active')
            })
        }

        function setFirstTitleActive() {
            hideColorTitles()
            $('.rack-active .color-option-container').first().children('.color-title').addClass('color-title-active')
        }
    }
})