import '@fortawesome/fontawesome-free/css/all.css'

// Import Foundation
import './init-foundation'

// import main SASS file
import '../../styles/scss/style.scss'

// import JS modules to import at runtime
import './modules/lazyload'
import './modules/fancybox'
import './modules/submenu'
import './modules/forms'
import './modules/stickyNav'
import './modules/ie'
import './modules/modules'
import './modules/futureForest'
import './modules/googleTranslate'
import './modules/specs'
import './modules/productBanner'
import './modules/productBannerWithVersions'
import './modules/scroll'
import './modules/altProduct'
import './modules/productShopCallout'
import './modules/productFeatures'
import './modules/featureSlider'
import './modules/accessoriesCallout'
import './modules/featureVideo'
import './modules/tabularFitGuide'
import './modules/vehicleModelDropdown'

var modules = [{
    class: '.split-text',
    loaded: false
},
    {
        class: '.split-text-content',
        loaded: false
    },
    {
        class: '.content-fade',
        loaded: false
    },
    {
        class: '.video-side-content',
        loaded: false
    },
    {
        class: '.features-list',
        loaded: false
    },
    {
        class: '.review-slider',
        loaded: false
    },
    {
        class: '.review-slider-alt',
        loaded: false
    },
    {
        class: '.details-with-icons',
        loaded: false
    },
    {
        class: '.module-callout',
        loaded: false
    },
    {
        class: '.split-callout',
        loaded: false
    },
    {
        class: '.page-template-where_to_buy',
        loaded: false
    },
    {
        class: '.product-image',
        loaded: false
    },
    {
        class: '.page-template-where_to_buy',
        loaded: false
    },
    {
        class: '.content-slider',
        loaded: false
    },
    {
        class: '.category-split',
        loaded: false
    },
    {
        class: '.category',
        loaded: false
    },
    {
        class: '.nav-product',
        loaded: false
    },
    // {class: '.nav-category', loaded: false},
    {
        class: '.product-detail-no-versions',
        loaded: false
    },
    {
        class: '.product-detail-versions',
        loaded: false
    },
    {
        class: '.where-to-buy',
        loaded: false
    },
    {
        class: '.where-to-buy-alt',
        loaded: false
    },
    {
        class: '.product-slider-alt',
        loaded: false
    },

    {
        class: '.split-image-overlap',
        loaded: false
    },
    {
        class: '.product-slider',
        loaded: false
    },
]

// var stickyElement = document.querySelector('.alt-product-container');
// var parent = stickyElement.parentElement;
// while (parent) {
//     var hasOverflow = getComputedStyle(parent).overflow;
//     if(hasOverflow != 'visible') {
//         console.log(hasOverflow, parent);
//     } else {
//         console.log('nope')
//     }
//     parent = parent.parentElement;
// }

document.addEventListener("DOMContentLoaded", function () {
    modules.forEach((obj, index) => {
        var moduleSelector = document.querySelectorAll(obj.class)

        if ("IntersectionObserver" in window) {
            let moduleObserver = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {

                        // Split Text
                        if (obj.class === '.split-text' && !obj.loaded) {
                            import('./modules/splitText').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }


                        // Split Text & Content
                        // if (obj.class === '.split-text-content' && !obj.loaded) {
                        //     import('./modules/splitTextContent').then(function (module) {
                        //         // Render module
                        //         module.render()
                        //         obj.loaded = true;
                        //         moduleSelector.forEach((module, index) => {
                        //             moduleObserver.unobserve(module, {
                        //                 threshold: 0.0
                        //             })
                        //         })
                        //     })
                        // }

                        // Content Fade
                        if (obj.class === '.content-fade' && !obj.loaded) {
                            import('./modules/contentFade').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Video & Side Content
                        if (obj.class === '.video-side-content' && !obj.loaded) {
                            import('./modules/videoSideContent').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Features List
                        if (obj.class === '.features-list' && !obj.loaded) {
                            import('./modules/featuresList').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Review Slider
                        if (obj.class === '.review-slider' && !obj.loaded) {
                            import('./modules/reviewSlider').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Review Slider Alt
                        if (obj.class === '.review-slider-alt' && !obj.loaded) {
                            import('./modules/reviewSlider').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Details with Icons
                        if (obj.class === '.details-with-icons' && !obj.loaded) {
                            import('./modules/details').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Callout
                        if (obj.class === '.module-callout' && !obj.loaded) {
                            import('./modules/callout').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Split Image Callout
                        if (obj.class === '.split-callout' && !obj.loaded) {
                            import('./modules/splitImageCallout').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Where to Buy
                        if (obj.class === '.where-to-buy' && !obj.loaded) {
                            import('./modules/whereToBuy').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Where to Buy Alt
                        if (obj.class === '.where-to-buy-alt' && !obj.loaded) {
                            import('./modules/whereToBuy').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Product Slider
                        if (obj.class === '.product-slider' && !obj.loaded) {
                            import('./modules/productSlider').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Product Slider Alt
                        if (obj.class === '.product-slider-alt' && !obj.loaded) {
                            import('./modules/productSlider').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Feature Slider
                        if (obj.class === '.split-image-overlap' && !obj.loaded) {
                            import('./modules/splitImageOverlap').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Locator Retailer
                        if (obj.class === '.page-template-where_to_buy' && !obj.loaded) {
                            import('./modules/locatorRetailer').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Product Images
                        // if (obj.class === '.product-image' && !obj.loaded) {
                        //     import('./modules/productImages').then(function(module) {
                        //         // Render module
                        //         module.render()
                        //         obj.loaded = true;
                        //         moduleSelector.forEach((module, index) => {
                        //             moduleObserver.unobserve(module, {
                        //                 threshold: 0.0
                        //             })
                        //         })
                        //     })
                        // }



                        // Content Slider
                        if (obj.class === '.content-slider' && !obj.loaded) {
                            import('./modules/contentSlider').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Category Split
                        if (obj.class === '.category-split' && !obj.loaded) {
                            import('./modules/categorySplit').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Category
                        if (obj.class === '.category' && !obj.loaded) {
                            import('./modules/category').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Nav Product
                        if (obj.class === '.nav-product' && !obj.loaded) {
                            import('./modules/navProduct').then(function (module) {
                                // Render module
                                module.render()
                                obj.loaded = true;
                                moduleSelector.forEach((module, index) => {
                                    moduleObserver.unobserve(module, {
                                        threshold: 0.0
                                    })
                                })
                            })
                        }

                        // Nav Category
                        // if (obj.class === '.nav-category' && !obj.loaded) {
                        //     import('./modules/navCategory').then(function(module) {
                        //         // Render module
                        //         module.render()
                        //         obj.loaded = true;
                        //         moduleSelector.forEach((module, index) => {
                        //             moduleObserver.unobserve(module, {
                        //                 threshold: 0.0
                        //             })
                        //         })
                        //     })
                        // }

                        // Product Detail Without Versions
                        // if (obj.class === '.product-detail-no-versions' && !obj.loaded) {
                        //     import('./modules/productBanner').then(function(module) {
                        //         // Render module
                        //         module.render()
                        //         obj.loaded = true;
                        //         moduleSelector.forEach((module, index) => {
                        //             moduleObserver.unobserve(module, {
                        //                 threshold: 0.0
                        //             })
                        //         })
                        //     })
                        // }

                        // Product Detail WITH Versions
                        // if (obj.class === '.product-detail-versions' && !obj.loaded) {
                        //     import('./modules/productBannerWithVersions').then(function (module) {
                        //         // Render module
                        //         module.render()
                        //         obj.loaded = true;
                        //         moduleSelector.forEach((module, index) => {
                        //             moduleObserver.unobserve(module, {
                        //                 threshold: 0.0
                        //             })
                        //         })
                        //     })
                        // }

                    }

                })
            })

            if (moduleSelector !== undefined) {
                moduleSelector.forEach((module, index) => {
                    moduleObserver.observe(module, {
                        threshold: 0.0
                    })
                })
            }

        } else {
            // Possibly fall back to a more compatible method here
        }
    })

})