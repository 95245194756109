import { CountUp } from 'countup.js'
import $ from 'jquery'
import {Scene} from 'scrollmagic'
import 'scrollmagic/scrollmagic/minified/plugins/animation.gsap.min'
import globals from './globals'

$(document).ready(() => {
    var counter = document.querySelector('#counter')

    if (counter != undefined || counter != null) {
        var counterValue = parseInt(counter.dataset.counterValue)

        window.onload = function () {
            var countUp = new CountUp('counter', counterValue, {
                duration: 5
            })
            countUp.start()
        }
    }

    $('.anim-container').each((index, obj) => {
        // TEXT
        var fadeUpAnimation = new TimelineMax()
            .fromTo($(obj).find('.fadeup-anim'), 1, {
                yPercent: 30,
                opacity: 0,
                rotation:0.01,
                ease: Power4.easeInOut
            }, {
                opacity: 1,
                yPercent: 0,
            }, '+=0')
            .to($(obj).find('.fadeup-anim'), 3, {
                yPercent: -40,
                opacity: 0.1,
            })

        // create scene to pin and link animation
        var fadeUpscene = new Scene({
                triggerElement: obj,
                triggerHook: 'onCenter',
                duration: '200%',
                offset: 0
            })
            .setTween(fadeUpAnimation)
            .addTo(globals.controller)


        //  Move up without the fade
        var upAnimation = new TimelineMax()
            .fromTo($(obj).find('.up-anim'), 1, {
                yPercent: 30,
                rotation:0.01,
                ease: Power4.easeInOut
            }, {
                yPercent: 0,
            }, '+=0')
            .to($(obj).find('.up-anim'), 3, {
                yPercent: -40,
            })

        // create scene to pin and link animation
        var animUpscene = new Scene({
                triggerElement: obj,
                triggerHook: 'onCenter',
                duration: '200%',
                offset: 0
            })
            .setTween(upAnimation)
            .addTo(globals.controller)
    })


    // Pins Info Box
    var pins = document.querySelectorAll('.pin')
    pins.forEach((obj, index) => {
        obj.addEventListener('mouseover', (e) => {
            e.preventDefault()
            obj.querySelector('.info').style.display = 'block'
        })
        obj.addEventListener('mouseout', (e) => {
            e.preventDefault()
            obj.querySelector('.info').style.display = 'none'
        })
    })


    // Image slideshow
    $(".slideshow > div:gt(0)").hide();

    setInterval(function() {
    $('.slideshow > div:first')
        .fadeOut(1000)
        .next()
        .fadeIn(1000)
        .end()
        .appendTo('.slideshow');
    }, 3000);
})
