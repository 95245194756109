import $ from 'jquery'
import {
    Scene
} from 'scrollmagic'
import 'scrollmagic/scrollmagic/minified/plugins/animation.gsap.min'
import globals from './globals'
import anime from 'animejs'

document.addEventListener("DOMContentLoaded", function () {
    if ($('.product-detail').hasClass('product-detail-versions')) {
        const alternateAnimation = $('#product-banner').attr('data-alternate-animation')

        var specsProductImage = document.querySelector('.specs-product-image img'),
            activeProductImage = document.querySelector('.vehicle-container .rack-active img')

        var rackOut,
            rackIndex,
            versionActive,
            optionClicked = false,
            racksStart = $('#racks').attr('data-start-y'),
            racksEnd = $('#racks').attr('data-end-y'),
            racksMobileStart = $('#racks').attr('data-mobile-start-y'),
            racksMobileEnd = $('#racks').attr('data-mobile-end-y'),
            racksXsmallStart = $('#racks').attr('data-xsmall-start-y'),
            racksXsmallEnd = $('#racks').attr('data-xsmall-end-y'),
            racksMobileLeft = $('#racks').attr('data-mobile-left'),
            racksLeft = $('#racks').attr('data-left'),
            racksMobileMaxWidth = $('#racks').attr('data-mobile-max-width'),
            racksMaxWidth = $('#racks').attr('data-max-width'),
            racksMaxHeight = $('#racks').attr('data-max-height'),
            vehicleMaxWidth = $('#vehicle').attr('data-vehicle-max-width'),
            vehicleMaxHeight = $('#vehicle').attr('data-vehicle-max-height'),
            vehicleIsSideShot = $('#vehicle').attr('data-is-side-shot')

        var animVariables = {
            rackOption: {
                x: $(window).width() < 1200 ? -20 : -100
            },
            rack: {
                start: $(window).width() < 640 ? racksMobileStart : racksStart,
                end: $(window).width() < 640 ? racksMobileEnd : racksEnd,
                maxWidth: $(window).width() < 640 ? racksMobileMaxWidth : racksMaxWidth,
                scale: $(window).width() < 640 ? '1.08' : '1.1'
            },
        }

        var rackPositions = {},
            windowWidth = $(window).width()

        if (windowWidth >= 1024) {
            rackPositions.start = racksStart
            rackPositions.end = racksEnd
        }
        if (windowWidth < 1024 && !windowWidth < 480) {
            rackPositions.start = racksMobileStart
            rackPositions.end = racksMobileEnd
        }
        if (windowWidth < 480) {
            rackPositions.start = racksXsmallStart
            rackPositions.end = racksXsmallEnd
        }


        var vehicle = {
            maxWidth: vehicleMaxWidth,
            maxHeight: vehicleMaxHeight,
            bottom: $(window).width() < 640 ? '40%' : '10%',
            yPercent: $(window).width() < 640 ? 60 : 0,
        }

        var vehicleWithVersions = {
            bottom: $(window).width() < 640 ? '50%' : '40%'
        }

        var product = {
            start: animVariables.rack.start,
            end: animVariables.rack.end,
            mobileMaxWidth: racksMobileMaxWidth,
            maxWidth: animVariables.rack.maxWidth,
            maxHeight: racksMaxHeight,
            left: racksLeft
        }

        // vehicle with versions
        var vehicleAlternate;
        if (vehicleIsSideShot === '1') {
            vehicleAlternate = {
                bottom: $(window).width() < 640 ? '30%' : '40%'
            }

        } else {
            vehicleAlternate = {
                bottom: '50%'
            }
        }

        // Vehicle without versions
        var vehicleNotAlternate;
        if (vehicleIsSideShot === '1') {
            vehicleNotAlternate = {
                bottom: $(window).width() < 640 ? '30%' : '40%'
            }

        } else {
            vehicleNotAlternate = vehicleWithVersions
        }


        // Alternate Animation
        if (alternateAnimation === '1') {
            // define movement of panels
            var bannerAnimation = new TimelineMax()
                // animate to second panel
                .fromTo("#banner .heading-1", 1, {
                    scale: 1
                }, {
                    scale: 2
                })
                .to("#banner .subtitle", 1, {
                    scale: 1.5,
                    top: '50%'
                }, '-=1')
                .fromTo("#banner", 0.5, {
                    backgroundColor: 'rgba(255, 255, 255, 1)'
                }, {
                    backgroundColor: 'rgba(255, 255, 255, 0)'
                })
                .fromTo("#banner .heading-1", 0.5, {
                    autoAlpha: 1
                }, {
                    autoAlpha: 0
                }, '-=0.5')
                .fromTo("#banner .subtitle", 0.5, {
                    autoAlpha: 1
                }, {
                    css: {
                        autoAlpha: 0
                    }
                }, '-=0.5')
                .to("#banner .subtitle", 0.1, {
                    top: '60%'
                })
                .fromTo(".background-container .vehicle", 1, {
                    maxWidth: vehicle.maxWidth,
                    maxHeight: vehicle.maxHeight,
                    xPercent: -50,
                    bottom: '50%',
                    yPercent: 50
                }, {
                    bottom: vehicleAlternate.bottom,
                    yPercent: 60
                }, '-=1.5')
                .fromTo("#banner .main-background-animate", 1, {
                    autoAlpha: 1
                }, {
                    autoAlpha: 0
                }, '+=1.8')
                .fromTo(".background-container .vehicle-image", 1, {
                    autoAlpha: 1
                }, {
                    autoAlpha: 0
                }, '-=1')
                // add version controls
                .fromTo("#banner .versions-control", 1, {
                    autoAlpha: 0
                }, {
                    autoAlpha: 1
                }, '-=1')
                .to(".background-container .vehicle", 0, {
                    bottom: '50%',
                    yPercent: 50
                })
                .fromTo(".background-container .rack", 1, {
                    autoAlpha: 0,
                    yPercent: 50,
                    maxWidth: animVariables.rack.maxWidth,
                    maxHeight: animVariables.rack.maxHeight
                }, {
                    autoAlpha: 1
                })
                .fromTo(".background-container .rack img", 1, {
                    top: '50%',
                    left: '50%',
                    xPercent: -50,
                    yPercent: -50,
                    maxWidth: animVariables.rack.maxWidth,
                    scale: 1.5
                }, {
                    scale: 1
                }, '-=1')
                // .fromTo(".background-container .rack img", 1, {css: {transform: 'scale(1)'}}, {css: {transform: 'scale(1.3)'}}, '-=1')
                // .to("#banner .subtitle", 1.5, {autoAlpha: 1}, '-=1')
                .fromTo(".rack-option-with-multiple", 1, {
                    autoAlpha: 0,
                    x: 0
                }, {
                    autoAlpha: 1,
                    x: animVariables.rackOption.x,
                    ease: Sine.easeIn
                }, '-=1')
                .fromTo(".background-container .rack", 0.5, {}, {})

            function initBannerScene() {
                // create scene to pin and link animation
                return new Scene({
                    triggerElement: "#banner-pin",
                    triggerHook: "onLeave",
                    offset: 0,
                    duration: '300%'
                })
                    .setPin("#banner-pin")
                    .setTween(bannerAnimation)
                    .addTo(globals.controller)
            }
            var bannerScene = initBannerScene()

            bannerScene.on("progress", function (event) {
                if (event.progress >= 0.84 && !optionClicked) {
                    // rackTransition(0, 1000)
                }
            })

            $(window).on('resize', function () {
                bannerScene.destroy(true);
                bannerScene = initBannerScene();
            })
        }
        // Default Animation
        else {
            if ($(window).width() >= 1024) {
                // STICKY VIDEO
                var frameNumber = 0, // start video at frame 0
                    // lower numbers = faster playback
                    playbackConst = 1000,
                    // get page height from video duration
                    scroller = document.getElementById("product-scroller"),
                    container = document.querySelector(".product-sticky-video"),
                    rackOptionsContainer = document.querySelector(".rack-options-container"),
                    subtitle = document.querySelector(".subtitle"),
                    hasScrolled = false,
                    videoDuration,
                    // select video element         
                    vid = document.getElementById('product-video'),
                    videoHasPlayed = false,
                    targetElement = document.querySelector('.someElement'),
                    videoScrolledPast = false
                // var vid = $('#v0')[0]; // jquery option



                // dynamically set the page height according to video length
                vid.addEventListener('loadedmetadata', function () {
                    setTimeout(() => {
                        vid.play()
                    }, 1000)
                    
                    // videoDuration = vid.duration
                    // if (window.pageYOffset > 200) {
                    //     var distanceFromTop = scroller.getBoundingClientRect().top

                    //     if (!videoHasPlayed) {
                    //         subtitle.classList.add('hide')
                    //         vid.currentTime = videoDuration * 30
                    //         scroller.style.height = (window.innerHeight - distanceFromTop) + "px"
                    //     }
                    // }
                })

                // window.addEventListener('scroll', (e) => {
                //     hasScrolled = true
                // })

                // Use requestAnimationFrame for smooth playback
                function scrollPlay() {
                    var frameNumber = window.pageYOffset / playbackConst

                    if (hasScrolled && window.pageYOffset > 0 && window.pageYOffset < 200) {
                        if (!videoHasPlayed) {
                            vid.play()
                            vid.currentTime = 0
                            videoHasPlayed = true
                            // disableBodyScroll(targetElement, {reserveScrollBarGap: true})
                            // container.classList.add('addPadding')
                            subtitle.classList.add('fadeOut')


                            vid.onended = (event) => {
                                var distanceFromTop = scroller.getBoundingClientRect().top
                                scroller.style.height = (window.innerHeight - distanceFromTop) + "px"
                            }
                        }
                    }
                    if (window.pageYOffset <= 0 && videoHasPlayed) {
                        videoHasPlayed = false
                        vid.pause()
                        vid.currentTime = frameNumber

                        subtitle.classList.remove('fadeOut', 'hide')
                        scroller.style.height = "4000px"
                    }
                    if (frameNumber > 1) {
                        // enableBodyScroll(targetElement)
                    }
                    window.requestAnimationFrame(scrollPlay)
                }
                window.requestAnimationFrame(scrollPlay)
            }






            var detailSelect = {
                start: $(window).width() < 1024 ? {
                    opacity: 0
                } : {
                    yPercent: -0,
                    x: animVariables.rackOption.x,
                    opacity: 0
                },
                end: $(window).width() < 1024 ? {
                    opacity: 1,
                    ease: Sine.easeIn
                } : {
                    opacity: 1,
                    x: animVariables.rackOption.x,
                    ease: Sine.easeIn
                }
            }

            var animation2 = new TimelineMax()
                .fromTo(".detail-select-desktop", 1, {
                    autoAlpha: 0,
                    x: 300,
                    y: '-50%'
                }, {
                    autoAlpha: 1,
                    x: 0
                })


            function initBannerScene() {
                var scene2 = new Scene({
                    triggerElement: "#banner-slide-2",
                    triggerHook: 1,
                    offset: -100,
                    duration: '100%',
                })
                    .setTween(animation2)
                    .addTo(globals.controller)
            }
            var bannerScene = initBannerScene()
            $(window).on('resize', function () {
                if (bannerScene !== undefined) {
                    bannerScene.destroy(true);
                    bannerScene = initBannerScene();
                }
            })

        }

        // var bannerScene = initBannerScene()
        // bannerScene.on("progress", function (event) {
        //     if (event.progress >= 0.84 && !optionClicked && moreThanOne) {
        //         rackTransition(1, 1000)
        //         hideColorTitles()
        //         $('.rack-option .color-option-container').eq(1).children('.color-title').addClass('color-title-active')
        //     }
        // })




        // Set Price to first version
        var price = $('#version-1').attr('data-price')
        $('#price-desktop').html(price)
        $('#price-mobile').html(price)
        $('.product-price').html(price)

        var versionActive,
            optionClicked = false,
            versionClicked = false

        // Versions Control
        $('.version-control').each((index, obj) => {

            if (globals.elementInDom('.version-control-dropdown')) {
                const versionDropdown = document.querySelector('.version-control-dropdown')

                versionDropdown.addEventListener("change", () => {
                    const selectedVersion = versionDropdown.options[versionDropdown.selectedIndex].dataset.versionIndex
                    const selectedRack = versionDropdown.options[versionDropdown.selectedIndex].dataset.rackIndex

                    // Remove versions controls active
                    removeVersionControlActive()
                    // Active versions control state
                    $('#version-control-' + selectedVersion).addClass('version-control-active')

                    // Remove versions active
                    removeVersionsActive()
                    // Add version active
                    $('#version-' + selectedVersion).addClass('version-active')

                    // Remove all multiple details
                    removeDetailsActive()
                    $('.details-version-' + selectedVersion).addClass('details-active')

                    // Version Price
                    var price = $('#version-' + selectedVersion).attr('data-price')
                    $('#price-desktop').html(price)
                    $('#price-mobile').html(price)
                    $('.product-price').html(price)

                    // Fade in
                    anime({
                        targets: '#version-' + selectedVersion,
                        opacity: [0, 1],
                        easing: 'easeInOutQuad'
                    })

                    // Remove racks active
                    removeRacksActive()
                    $('#version-' + selectedVersion + '-rack-' + 1).addClass('rack-active')
                    anime({
                        targets: '#version-' + selectedVersion + '-rack-1',
                        opacity: [0, 1],
                        easing: 'easeInOutQuad'
                    })

                    // Remove racks Options active
                    removeRacksOptionActive()
                    // Add rack option active
                    $('#detail-rack-option-' + selectedVersion).addClass('rack-active')

                    versionClicked = true
                    // Update color switcher
                    // Update specs product image src
                    specsProductImage.src = document.querySelector('#version-' + selectedVersion + '-rack-' + selectedRack + ' img').getAttribute('src')

                    removeRacksActive()
                    $('#version-' + selectedVersion + '-rack-' + selectedRack).addClass('rack-active')

                    hideColorTitles()
                    $(obj).parent('.color-option-container').children('.color-title').addClass('color-title-active')

                    anime({
                        targets: '#version-' + selectedVersion + '-rack-' + selectedRack,
                        opacity: [0, 1],
                        easing: 'easeInOutQuad'
                    })

                    // Switch module product images to match color switcher
                    removeProductImagesActive()
                    $('#product-image-' + selectedRack).addClass('option-active')
                    $('#split-content-product-image-' + selectedRack).addClass('option-active')
                    $('#product-hovers-product-image-' + selectedRack).addClass('option-active')

                    optionClicked = true

                    // Set module product images
                    removeProductImagesActive()
                    $('#product-image-1').addClass('option-active')
                    $('#split-content-product-image-1').addClass('option-active')
                    $('#product-hovers-product-image-1').addClass('option-active')
                });
            }

            obj.addEventListener('click', (e) => {
                e.preventDefault()

                // Set version active by index
                if (index === 0) {
                    versionActive = 1
                } else if (index === 1) {
                    versionActive = 2
                } else if (index === 2) {
                    versionActive = 3
                } else if (index === 3) {
                    versionActive = 4
                } else if (index === 4) {
                    versionActive = 5
                } else if (index === 5) {
                    versionActive = 6
                }

                // Remove versions controls active
                removeVersionControlActive()
                // Active versions control state
                $('#version-control-' + versionActive).addClass('version-control-active')

                // Remove versions active
                removeVersionsActive()
                // Add version active
                $('#version-' + versionActive).addClass('version-active')

                // Remove all multiple details
                removeDetailsActive()
                $('.details-version-' + versionActive).addClass('details-active')

                // Version Price
                var price = $('#version-' + versionActive).attr('data-price')
                $('#price-desktop').html(price)
                $('#price-mobile').html(price)
                $('.product-price').html(price)

                // Fade in
                anime({
                    targets: '#version-' + versionActive,
                    opacity: [0, 1],
                    easing: 'easeInOutQuad'
                })

                // Remove racks active
                removeRacksActive()
                $('#version-' + versionActive + '-rack-' + 1).addClass('rack-active')
                anime({
                    targets: '#version-' + versionActive + '-rack-1',
                    opacity: [0, 1],
                    easing: 'easeInOutQuad'
                })

                // Remove racks Options active
                removeRacksOptionActive()
                // Add rack option active
                $('#detail-rack-option-' + versionActive).addClass('rack-active')

                versionClicked = true
                // Update color switcher
                colorSwitch()

                // Set module product images
                removeProductImagesActive()
                $('#product-image-1').addClass('option-active')
                $('#split-content-product-image-1').addClass('option-active')
                $('#product-hovers-product-image-1').addClass('option-active')
            })
        })

        function removeVersionControlActive() {
            $('.version-control').each((index, obj) => {
                $(obj).removeClass('version-control-active')
            })
        }

        function removeDetailsActive() {
            $('.details-multiple').each((index, obj) => {
                $(obj).removeClass('details-active')
            })
        }

        function removeVersionsActive() {
            $('.version').each((index, obj) => {
                $(obj).removeClass('version-active')
            })
        }

        function removeRacksOptionActive() {
            $('.rack-option-with-multiple').each((index, obj) => {
                $(obj).removeClass('rack-active')
            })
        }

        function removeRacksActive() {
            $('.rack').each((index, obj) => {
                $(obj).removeClass('rack-active')
            })
        }

        function colorSwitch() {

            if (versionClicked) {
                setFirstTitleActive()
                versionClicked = false
            }
            // Color switcher
            $('.rack-option-with-multiple.rack-active .detail-color-option').each((index, obj) => {
                var rackIndex = index += 1

                $(obj).on('click', (e) => {
                    e.preventDefault()

                    var version = $(obj).attr('data-version')

                    console.log(version)

                    // Update specs product image src
                    specsProductImage.src = document.querySelector('#version-' + version + '-rack-' + rackIndex + ' img').getAttribute('src')

                    removeRacksActive()
                    $('#version-' + version + '-rack-' + rackIndex).addClass('rack-active')

                    hideColorTitles()
                    $(obj).parent('.color-option-container').children('.color-title').addClass('color-title-active')

                    anime({
                        targets: '#version-' + version + '-rack-' + rackIndex,
                        opacity: [0, 1],
                        easing: 'easeInOutQuad'
                    })

                    // Switch module product images to match color switcher
                    removeProductImagesActive()
                    $('#product-image-' + rackIndex).addClass('option-active')
                    $('#split-content-product-image-' + rackIndex).addClass('option-active')
                    $('#product-hovers-product-image-' + rackIndex).addClass('option-active')

                    optionClicked = true
                })
            })
        }
        colorSwitch()

        function removeProductImagesActive() {
            $('.product-image').each((index, obj) => {
                $(obj).removeClass('option-active')
            })
        }

        function hideColorTitles() {
            $('.color-title').each((index, obj) => {
                $(obj).removeClass('color-title-active')
            })
        }

        function setFirstTitleActive() {
            hideColorTitles()
            $('.rack-active .color-option-container').first().children('.color-title').addClass('color-title-active')
        }
    }
})