import { gsap, Power2 } from "gsap3:";
import { ScrollTrigger } from "gsap3:/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger)

let tl = gsap.timeline({
    // yes, we can add it to an entire timeline!
    scrollTrigger: {
        trigger: '.alt-product-pin',
        start: 'top-=200px top',
        end:  '+=150%',
        scrub: true,
        pin: '.alt-product-pin',
        pinSpacing: true,
    }

})

tl.fromTo('.hero-background',
    {opacity: '1'},
    {opacity: '0', duration: 100}
)
tl.fromTo('.alt-product-image img',
    {opacity: '1'},
    {opacity: '0.07', duration: 300}
)
tl.fromTo('.alt-product-content',
    {opacity: '0'},
    {opacity: '1', duration: 500}
)
