import $ from 'jquery'
import {
    Scene,
    Controller
} from 'scrollmagic'
import 'scrollmagic/scrollmagic/minified/plugins/animation.gsap.min'
import globals from './globals'
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks
} from 'body-scroll-lock';


if ($('.product-detail').hasClass('product-detail-no-versions') && !$('.product-detail').hasClass('alt-product')) {
    const alternateAnimation = $('#product-banner').attr('data-alternate-animation')

    var specsProductImage = document.querySelector('.specs-product-image img'),
        activeProductImage = document.querySelector('.vehicle-container .rack-active img')

    document.addEventListener("DOMContentLoaded", function () {
        if ($(window).width() >= 1024) {
            // STICKY VIDEO
            var frameNumber = 0, // start video at frame 0
                // lower numbers = faster playback
                playbackConst = 1000,
                // get page height from video duration
                scroller = document.getElementById("product-scroller"),
                container = document.querySelector(".product-sticky-video"),
                rackOptionsContainer = document.querySelector(".rack-options-container"),
                subtitle = document.querySelector(".subtitle"),
                hasScrolled = false,
                videoDuration,
                // select video element         
                vid = document.getElementById('product-video'),
                videoHasPlayed = false,
                targetElement = document.querySelector('.someElement'),
                videoScrolledPast = false
            // var vid = $('#v0')[0]; // jquery option



            // dynamically set the page height according to video length
            vid.addEventListener('loadedmetadata', function () {
                setTimeout(() => {
                    vid.play()
                }, 1000)

                // videoDuration = vid.duration
                // if (window.pageYOffset > 200) {
                //     var distanceFromTop = scroller.getBoundingClientRect().top

                //     if (!videoHasPlayed) {
                //         subtitle.classList.add('hide')
                //         vid.currentTime = videoDuration * 30
                //         scroller.style.height = (window.innerHeight - distanceFromTop) + "px"
                //     }
                // }
            })

            // window.addEventListener('scroll', (e) => {
            //     hasScrolled = true
            // })

            // Use requestAnimationFrame for smooth playback
            function scrollPlay() {
                var frameNumber = window.pageYOffset / playbackConst

                if (hasScrolled && window.pageYOffset > 0 && window.pageYOffset < 200) {
                    if (!videoHasPlayed) {
                        vid.play()
                        vid.currentTime = 0
                        videoHasPlayed = true
                        // disableBodyScroll(targetElement, {reserveScrollBarGap: true})
                        // container.classList.add('addPadding')
                        subtitle.classList.add('fadeOut')


                        vid.onended = (event) => {
                            var distanceFromTop = scroller.getBoundingClientRect().top
                            scroller.style.height = (window.innerHeight - distanceFromTop) + "px"
                        }
                    }
                }
                if (window.pageYOffset <= 0 && videoHasPlayed) {
                    videoHasPlayed = false
                    vid.pause()
                    vid.currentTime = frameNumber

                    subtitle.classList.remove('fadeOut', 'hide')
                    scroller.style.height = "4000px"
                }
                if (frameNumber > 1) {
                    // enableBodyScroll(targetElement)
                }
                window.requestAnimationFrame(scrollPlay)
            }
            window.requestAnimationFrame(scrollPlay)
        }
    })



    var rackOut,
        rackIndex,
        optionClicked = false,
        moreThanOne = true,
        racksStart = $('#racks').attr('data-start-y'),
        racksEnd = $('#racks').attr('data-end-y'),
        racksMobileStart = $('#racks').attr('data-mobile-start-y'),
        racksMobileEnd = $('#racks').attr('data-mobile-end-y'),
        racksXsmallStart = $('#racks').attr('data-xsmall-start-y'),
        racksXsmallEnd = $('#racks').attr('data-xsmall-end-y'),
        racksMobileLeft = $('#racks').attr('data-mobile-left'),
        racksLeft = $('#racks').attr('data-left'),
        racksMobileMaxWidth = $('#racks').attr('data-mobile-max-width'),
        racksMaxWidth = $('#racks').attr('data-max-width'),
        racksMaxHeight = $('#racks').attr('data-max-height'),
        vehicleMaxWidth = $('#vehicle').attr('data-vehicle-max-width'),
        vehicleMaxHeight = $('#vehicle').attr('data-vehicle-max-height')

    // Responsive animaiton properties
    var animVariables = {
        rackOption: {
            x: $(window).width() < 1600 ? -20 : -100
        },
        rack: {
            start: $(window).width() < 640 ? racksMobileStart : racksStart,
            end: $(window).width() < 640 ? racksMobileEnd : racksEnd,
            maxWidth: $(window).width() < 640 ? racksMobileMaxWidth : racksMaxWidth,
            scale: $(window).width() < 640 ? '1.08' : '1.2'
        },
    }

    var rackPositions = {},
        windowWidth = $(window).width()

    if (windowWidth >= 1024) {
        rackPositions.start = racksStart
        rackPositions.end = racksEnd
    }
    if (windowWidth < 1024 && !windowWidth < 480) {
        rackPositions.start = racksMobileStart
        rackPositions.end = racksMobileEnd
    }
    if (windowWidth < 480) {
        rackPositions.start = racksXsmallStart
        rackPositions.end = racksXsmallEnd
    }


    var vehicle = {
        maxWidth: vehicleMaxWidth,
        maxHeight: vehicleMaxHeight,
        positionBottom: $(window).width() < 480 ? '40%' : '45%',
    }
    var vehiclePosition = vehicle.position

    var product = {
        start: animVariables.rack.start,
        end: animVariables.rack.end,
        mobileMaxWidth: racksMobileMaxWidth,
        maxWidth: animVariables.rack.maxWidth,
        maxHeight: racksMaxHeight,
        left: racksLeft
    }

    var detailSelect = {
        start: $(window).width() < 1024 ? {
            opacity: 0
        } : {
            yPercent: -0,
            x: animVariables.rackOption.x,
            opacity: 0
        },
        end: $(window).width() < 1024 ? {
            opacity: 1,
            ease: Sine.easeIn
        } : {
            opacity: 1,
            x: animVariables.rackOption.x,
            ease: Sine.easeIn
        }
    }


    var animation2 = new TimelineMax()
        .fromTo(".detail-select-desktop", 1, {
            autoAlpha: 0,
            x: 300,
            y: '-50%'
        }, {
            autoAlpha: 1,
            x: 0
        })


    function initBannerScene() {
        var scene2 = new Scene({
            triggerElement: "#banner-slide-2",
            triggerHook: 1,
            offset: -100,
            duration: '100%',
        })
            .setTween(animation2)
            .addTo(globals.controller)
    }
    var bannerScene = initBannerScene()
    $(window).on('resize', function () {
        if (bannerScene !== undefined) {
            bannerScene.destroy(true);
            bannerScene = initBannerScene();
        }
    })

    // Check if racks have more than one
    getRackCount()


    /// Color option select
    $('.color-option').each((index, obj) => {
        $(obj).on('click', (e) => {
            e.preventDefault()
            hideColorTitles()
            $(obj).parent('.color-option-container').children('.color-title').addClass('color-title-active')
            rackTransition(index, 1000)

            // Stop auto switching on scroll
            optionClicked = true
        })
    })


    // Detail Color Select
    $('.detail-color-option-mobile').each((index, obj) => {
        obj.addEventListener('click', (e) => {
            e.preventDefault()
            hideColorTitles()
            $('.color-option-' + $(obj).attr('data-index')).parent('.color-option-container').children('.color-title').addClass('color-title-active')
            rackTransition(index, 1000)

            // Stop auto switching on scroll
            optionClicked = true
        })
    })
    $('.detail-color-option-desktop').each((index, obj) => {
        obj.addEventListener('click', (e) => {
            e.preventDefault()
            hideColorTitles()
            $('.color-option-' + $(obj).attr('data-index')).parent('.color-option-container').children('.color-title').addClass('color-title-active')
            rackTransition(index, 1000)

            // Stop auto switching on scroll
            optionClicked = true
        })
    })

    function hideColorTitles() {
        $('.color-title').each((index, obj) => {
            $(obj).removeClass('color-title-active')
        })
    }

    function colorTitleTransition(indexActive) {
        $('.color-title').each((index, obj) => {
            if (index === indexActive) {
                $(obj).addClass('color-title-active')
            } else {
                $(obj).removeClass('color-title-active').fadeOut()
            }
        })
    }

    function getRackCount() {
        var $index = 0
        $('.rack, .detail-rack').each((index, obj) => {
            $index += index
        })
        if ($index === 0) {
            moreThanOne = false
        }
    }

    function rackTransition(index, speed) {
        var rackOut,
            rackIndex

        // set the rack to hide based on the clicked rack
        // reverse indexes to match color options order to be the alternative first
        rackIndex = index += 1

        // Update specs product image src
        specsProductImage.src = document.querySelector('#rack-' + rackIndex + ' img').getAttribute('src')

        // Remove all active option classes
        function removeAllActive() {
            $('.rack, .detail-rack').each((index, obj) => {
                $(obj).removeClass('rack-active')
            })
            $('.product-image').each((index, obj) => {
                $(obj).removeClass('option-active')
            })
            $('.rack-gallery .product-image').each((index, obj) => {
                $(obj).removeClass('product-active')
            })
        }

        if (!$('.rack-' + rackIndex + ', .detail-rack-' + rackIndex).hasClass('rack-active') && moreThanOne) {
            new Promise((resolve, reject) => {
                removeAllActive()
                $('.rack-' + rackIndex + ', .detail-rack-' + rackIndex).addClass('rack-active').addClass('fadeProduct')
                $('#product-image-' + rackIndex).addClass('option-active')
                $('#split-content-product-image-' + rackIndex).addClass('option-active')
                $('#product-hovers-product-image-' + rackIndex).addClass('option-active')
                $('.rack-gallery .product-image-' + rackIndex).addClass('product-active')



                if (alternateAnimation !== '1') {
                    $('.rack, detail-rack').not('.rack-' + rackIndex + ', .detail-rack-' + rackIndex).animate({
                        opacity: '0'
                    }, speed)
                    $('.rack-' + rackIndex + ', .detail-rack-' + rackIndex).animate({
                        opacity: '1'
                    }, speed)
                    setTimeout(() => {
                        resolve()
                    }, speed)
                }
            })
                .then(() => {

                })
                .catch((error) => {
                    console.log(error)
                })
        } else if ($('.rack-' + rackIndex + ', .rack-' + rackIndex).hasClass('rack-active') && $('.rack, .detail-rack').not('.rack-' + rackIndex + ', .detail-rack-' + rackIndex).hasClass('rack-active')) {
            // remove active class if both options are active
            removeAllActive()
        }

    }

    function getMilliSeconds(num) {
        return (num % 1) * 1000;
    }
}