import $ from 'jquery'

var allModules = document.querySelectorAll('.module'),
  accordianItems = document.querySelectorAll('.accordion-item')

allModules.forEach((obj, index) => {
  obj.setAttribute('data-module-index', (index += 1))
})

document.addEventListener("DOMContentLoaded", function () {
  // Back To Top
  document.getElementById('back-to-top').addEventListener('click', (e) => {
    e.preventDefault()
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  })

  document.querySelectorAll('.lifestyle img').forEach((obj, index) => {
    if ($(window).width() >= 1024) obj.style.objectPosition = obj.dataset.desktopPosition
    if ($(window).width() < 1024) obj.style.objectPosition = obj.dataset.mobilePosition
  })

  accordianItems.forEach((obj, index) => {
    obj.querySelector('a').addEventListener('click', (e) => {
      e.preventDefault()
      obj.classList.toggle('is-active')

    })
  })


})



// VIDEOS
// Automatically Pause and Play Video
// var observerTarget = document.querySelector('video'),
// videoReady = false

// var intersectionObserver = new IntersectionObserver(function (entries) {
// entries.forEach(entry => {
//     // if (!videoReady) {
//     //     observerTarget.addEventListener('canplay', (event) => {
//     //         entry.target.play()
//     //         videoReady = true;
//     //     })
//     // }
//     if (entry.isIntersecting) {
//         entry.target.play()
//     } else {
//         entry.target.pause()
//     }
// })
// })
// // If it isn't "undefined" and it isn't "null", then it exists.
// if (typeof (observerTarget) != 'undefined' && observerTarget != null) {
// // start observing
// intersectionObserver.observe(observerTarget)
// }

// FIND OVERFLOW CAUSING ELEMENTS
// var docWidth = document.documentElement.offsetWidth;

// [].forEach.call(
//   document.querySelectorAll('*'),
//   function(el) {
//     if (el.offsetWidth > docWidth) {
//       console.log(el);
//     }
//   }
// );