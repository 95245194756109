import $ from 'jquery'
import globals from './globals'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import {ResizeObserver as Polyfill} from '@juggle/resize-observer'

const ResizeObserver = window.ResizeObserver || Polyfill

let productsMenuSet = false,
    supportMenuSet = false,
    mobileMenuSet = false

$(document).ready(() => {
    // Product Hovers
    let baseURL = $('#body').attr('data-template-uri')
    var productNavEl = document.getElementById('nav-product'),
        productDetail = document.querySelector('.product-detail')

    // PRODUCTS //
    function productsSubMenu() {
        var firstSubMenu,
            firstAnchor

        $('#menu-menu-left li').each((index, obj) => {
            $(obj).removeClass('active')
        })

        // Add a mega menu class for the main sub menu
        // This is used to target the sub menu without styling child sub menus
        $('#menu-item-9482, #menu-item-10178').children('.sub-menu').first().addClass('mega-menu')

        if ($(window).width() >= 1024) {
            $('#menu-item-9482, #menu-item-10178').on('mouseover', function (e) {
                e.preventDefault()
                firstSubMenu = $(e.currentTarget).children('.sub-menu').first()
                firstAnchor = $(e.currentTarget).children('a').first()

                if (globals.elementInDom('.nav-product')) productNavEl.classList.remove('is-stuck')

                firstAnchor.focus()

                firstSubMenu.css({
                    visibility: 'visible',
                    opacity: '1',
                    display: 'flex',
                });
                firstSubMenu.on('mouseenter', function (e) {
                    e.preventDefault()
                })
                firstSubMenu.on('mouseleave', function (e) {
                    e.preventDefault()
                    firstSubMenu.css('display', 'none');
                    firstAnchor.blur()

                    if (globals.elementInDom('.nav-product') && productDetail.classList.contains('subnav-is-stuck')) {
                        navProductEl.classList.add('is-stuck')
                    }
                });
                // Hide on other hover link
                $('#menu-desktop .menu-item').not('#menu-item-9482, #menu-item-10178').on('mouseenter', function (e) {
                    e.preventDefault()
                    firstSubMenu.css('display', 'none')
                    firstAnchor.blur()
                })
            });
            
        }

        let states = {
            nvOne: baseURL + '/assets/images/product-hovers/nvOne.png',
            nvBaseOne: baseURL + '/assets/images/product-hovers/nvBaseOne.png',
            nv20: baseURL + '/assets/images/product-hovers/nv-20.png',
            nv20Addons: baseURL + '/assets/images/product-hovers/nv-2.0-addon.png',
            nv20Base: baseURL + '/assets/images/product-hovers/nv-20-base.png',
            nv20BaseAddon: baseURL + '/assets/images/product-hovers/nv20-base-addon.png',
            sherpa: baseURL + '/assets/images/product-hovers/sherpa.png',
            transfer: baseURL + '/assets/images/product-hovers/transfer.png',
            transferv2: baseURL + '/assets/images/product-hovers/transfer-v2.png',
            beta: baseURL + '/assets/images/product-hovers/beta.png',
            pivot: baseURL + '/assets/images/product-hovers/pivotv2.png',
            highline: baseURL + '/assets/images/product-hovers/highline-update.png',
            trio: baseURL + '/assets/images/product-hovers/trio.png',
            grip: baseURL + '/assets/images/product-hovers/grip.png',
            class2: baseURL + '/assets/images/product-hovers/class-2.png',
            class4: baseURL + '/assets/images/product-hovers/class-4.png',
            vagabondX: baseURL + '/assets/images/product-hovers/vagabond-x.png',
            miniSkinny: baseURL + '/assets/images/product-hovers/mini-skinny.png',
            switch: baseURL + '/assets/images/product-hovers/switch-black.png',
            truck: baseURL + '/assets/images/product-hovers/ibex.png',
            pistonProX: baseURL + '/assets/images/product-hovers/piston-pro-x.png',
            pistonProXAddons: baseURL + '/assets/images/product-hovers/piston-pro-x-addons.png',
            rackDock: baseURL + '/assets/images/product-hovers/rackdock.jpg',
            hiLoPro: baseURL + '/assets/images/product-hovers/hi-lo-pro.png',
            pivot: baseURL + '/assets/images/product-hovers/pivot.png',
            bikeRamp: baseURL + '/assets/images/product-hovers/bike-ramp.jpg',
            dirtbag: baseURL + '/assets/images/product-hovers/dirtbag.jpg',
            huk: baseURL + `/assets/images/product-hovers/huk.png`,
            pistonPro: baseURL + `/assets/images/product-hovers/piston_pro.png`,
            pistonSR: baseURL + `/assets/images/product-hovers/piston_sr.png`,
            ibex: baseURL + `/assets/images/product-hovers/ibex.png`,
            ibexPanelKit: baseURL + `/assets/images/product-hovers/ibex_panelKit.png`,
            pistonSRHitch: baseURL + `/assets/images/product-hovers/piston-sr-hitch.png`,
            default: baseURL + '/assets/images/product-hovers/class-2.png'
        }

        if (!productsMenuSet) {
            $('#menu-item-9482 ul, #menu-item-10178 ul').first().append(`<img id="hoverImage" src="${states.default}" alt="product hover image">`)
        }

        $('#menu-item-9482 .sub-menu li, #menu-item-10178 .sub-menu li').each((index, obj) => {
            $(obj).find('a')
            .on('mouseenter', (e) => {
                e.preventDefault()
                if ($(obj).hasClass('nvOne')) setHoverImage(states.nvOne)
                if ($(obj).hasClass('nvBaseOne')) setHoverImage(states.nvBaseOne)
                if ($(obj).hasClass('nv20')) setHoverImage(states.nv20)
                if ($(obj).hasClass('nv20Addon')) setHoverImage(states.nv20Addons)
                if ($(obj).hasClass('nvBase20')) setHoverImage(states.nv20Base)
                if ($(obj).hasClass('nv20BaseAddon')) setHoverImage(states.nv20BaseAddon)
                if ($(obj).hasClass('sherpa')) setHoverImage(states.sherpa)
                if ($(obj).hasClass('transfer')) setHoverImage(states.transfer)
                if ($(obj).hasClass('transfer-v2')) setHoverImage(states.transferv2)
                if ($(obj).hasClass('beta')) setHoverImage(states.beta)
                if ($(obj).hasClass('pivot')) setHoverImage(states.pivot)
                if ($(obj).hasClass('highline')) setHoverImage(states.highline)
                if ($(obj).hasClass('trio')) setHoverImage(states.trio)
                if ($(obj).hasClass('grip')) setHoverImage(states.grip)        
                if ($(obj).hasClass('class-2')) setHoverImage(states.class2)
                if ($(obj).hasClass('class-4')) setHoverImage(states.class4)
                if ($(obj).hasClass('vagabond-x')) setHoverImage(states.vagabondX)
                if ($(obj).hasClass('switch')) setHoverImage(states.switch)
                if ($(obj).hasClass('mini-skinny')) setHoverImage(states.miniSkinny)
                if ($(obj).hasClass('dirtbag')) setHoverImage(states.dirtbag)
                if ($(obj).hasClass('piston-pro-x')) setHoverImage(states.pistonProX)
                if ($(obj).hasClass('piston-pro-x-addons')) setHoverImage(states.pistonProXAddons)
                if ($(obj).hasClass('rackdock')) setHoverImage(states.rackDock)
                if ($(obj).hasClass('hi-lo-pro')) setHoverImage(states.hiLoPro)
                if ($(obj).hasClass('pivot')) setHoverImage(states.pivot)
                if ($(obj).hasClass('bike-ramp')) setHoverImage(states.bikeRamp)
                if ($(obj).hasClass('huk')) setHoverImage(states.huk)
                if ($(obj).hasClass('ibex')) setHoverImage(states.ibex)
                if ($(obj).hasClass('piston-pro')) setHoverImage(states.pistonPro)
                if ($(obj).hasClass('piston-sr')) setHoverImage(states.pistonSR)
                if ($(obj).hasClass('piston-sr-hitch')) setHoverImage(states.pistonSRHitch)
                if ($(obj).hasClass('ibex-panel-kit')) setHoverImage(states.ibexPanelKit)

                // Categories
                if ($(obj).hasClass('hitch')) setHoverImage(states.nv20)
                if ($(obj).hasClass('truck')) setHoverImage(states.truck)
                if ($(obj).hasClass('roof')) setHoverImage(states.grip)

                // Main Categories
                if ($(obj).hasClass('bike')) setHoverImage(states.nv20)
                if ($(obj).hasClass('water')) setHoverImage(states.class4)
                if ($(obj).hasClass('snow')) setHoverImage(states.grip)
                if ($(obj).hasClass('cargo')) setHoverImage(states.vagabondX)
                if ($(obj).hasClass('accessories')) setHoverImage(states.pivot)
            })
            .on('mouseleave', (e) => {
                e.preventDefault()
                setHoverImage(states.default)
            })
        })

        function setHoverImage(url) {
            $('#hoverImage')
            .hide()
            .attr('src', url)
            .fadeIn()
        }

        productsMenuSet = true
    }
  


    // SUPPORT
    function supportSubMenu() {
        var firstSubMenu,
            firstAnchor,
            baseURL = $('#body').attr('data-base-path'),
            assetBaseURL = $('#body').attr('data-template-uri')

        
        if (!supportMenuSet) {
            // Add a mega menu class for the main sub menu
            // This is used to target the sub menu without styling child sub menus
            $('#menu-item-9483').children('.sub-menu').first().addClass('mega-menu-support')
            $('#menu-item-9483').children('.sub-menu').first().wrapInner('<div id="mega-menu-support-wrapper" class="mega-menu-support-wrapper"></div>')

            // Callout
            var calloutBody = 
            `<div class="body">
                <h3 class="heading-3">Industry-Leading Limited Warranty</h3>
                <div class="cta-button">
                    <a class="btn-box" href="${baseURL}/warranty">
                        <div class="btn-container">
                            <span><div>Learn More</div></span>
                            <span><div>Learn More</div></span>
                        </div>
                    </a>
                </div>
            </div>
            `
            $('#menu-item-9483').children('.sub-menu').first().prepend(`<div id="callout" class="menu-callout">${calloutBody}</div>`)
            $('#callout').first().prepend(`<img src="${assetBaseURL + '/assets/images/support/warranty.jpg'}" class="background">`)
        }

        if ($(window).width() >= 1025) {
            $('#menu-item-9483').on('mouseenter', function (e) {
                e.preventDefault()
                firstSubMenu = $('#menu-item-9483').find('.sub-menu').first()
                firstAnchor = $('#menu-item-9483').children('a').first()

                firstAnchor.focus()

                firstSubMenu.css({
                    visibility: 'visible',
                    opacity: '1',
                    display: 'flex',
                });
                firstSubMenu.on('mouseenter', function (e) {
                    e.preventDefault()
                })
                firstSubMenu.on('mouseleave', function (e) {
                    e.preventDefault()
                    firstSubMenu.css('display', 'none');
                    firstAnchor.blur()
                });
            });
            // Hide on other hover link
            $('#menu-desktop .menu-item').not('#menu-item-9483, #menu-item-9483 .menu-item').on('mouseenter', function (e) {
                e.preventDefault();
                $('#menu-item-9483').find('.sub-menu').first().css('display', 'none')
                $('#menu-item-9483').find('.sub-menu').first().blur()
            })

            // About us hover - blur support link
            $('#menu-item-10421').on('mouseenter', function (e) {
                e.preventDefault()
                $('#menu-item-9483').find('.sub-menu').first().css('display', 'none')
                $('#menu-item-9483').children('a').first().blur()
            })
            
        }

        // $(window).on('resize', () => {
        //     if ($(window).width() >= 1025) {
        //         $('#menu-item-9483').on('mouseover', function (e) {
        //             e.preventDefault()
        //             $(this).children('.sub-menu').show();
        //             $(this).children('.sub-menu').css({
        //                 visibility: 'visible',
        //                 opacity: '1',
        //                 top: $('#nav').height() + 10
        //             });
        //         });
        //         $('.sub-menu')
        //             .on('mouseenter', function (e) {
        //                 e.preventDefault();
        //             })
        //             .on('mouseleave', function (e) {
        //                 e.preventDefault()
        //                 $('.sub-menu').hide()
        //             });
        //         $('.menu-item').not('#menu-item-20').on('mouseenter', function (e) {
        //             e.preventDefault();
        //             $('#menu-item-9483').children('.sub-menu').hide()
        //         })
        //     }
        // })

        supportMenuSet = true
    }


    // MOBILE MENU
    function mobileMenu() {
        var keepScrollTarget = document.getElementById('#menu-toggle')

        if (!mobileMenuSet) {
            // Remove old event listeners
            $('.menu-item').add('*').off();
            // Menu toggle
            $('#menu-toggle-trigger').on('click', (e) => {
                e.preventDefault()
                // Menu target
                if ($('#menu-toggle').hasClass('menu-slide')) {
                    globals.isMenuOpen = false
                    // Resume page scrolling
                    enableBodyScroll(keepScrollTarget)
                } else {
                    globals.isMenuOpen = true
                    // Stop page scrolling on page except menu
                    disableBodyScroll(keepScrollTarget)

                }
                $('#menu-toggle').toggleClass('menu-slide')
                $('#menu-toggle-trigger').toggleClass('close-icon')
            })


            // Products
            $('#menu-item-10298, #menu-item-10451').find('a').first().on('click', (e) => {
                e.preventDefault()
                $('#menu-item-10298, #menu-item-10451').find('.sub-menu').first().toggleClass('show-menu')
                $('#menu-item-10298, #menu-item-10451').find('a').first().toggleClass('menu-item-open')
                setTimeout(() => {
                    $('#menu-item-10298, #menu-item-10451').find('.sub-menu').first().toggleClass('make-visible')
                }, 100)
            })
            // Support
            $('#menu-item-10320, #menu-item-10459').find('a').first().on('click', (e) => {
                e.preventDefault()
                $('#menu-item-10320, #menu-item-10459').find('.sub-menu').first().toggleClass('show-menu')
                $('#menu-item-10320, #menu-item-10459').find('a').first().toggleClass('menu-item-open')
                setTimeout(() => {
                    $('#menu-item-10320, #menu-item-10459').find('.sub-menu').first().toggleClass('make-visible')
                }, 100)
            })

            // add arrows
            $('#menu-item-10298 a, #menu-item-10451 a').first().append('<span class="fas fa-chevron-down"></span>')
            $('#menu-item-10320 a, #menu-item-10459 a').first().append('<span class="fas fa-chevron-down"></span>')

            // Set Category SVGs
            $('#menu-toggle .bike').each((index, obj) => {
                $(obj).find('a').first().prepend('<img src="' + baseURL +'/assets/images/bike-blue.svg">')
                $(obj).find('a').first().append('<span class="fas fa-chevron-down"></span>')
                $(obj).find('a').first().on('click', (e) => {
                    e.preventDefault()
                    $(obj).toggleClass('menu-item-open')
                    $(obj).find('.sub-menu').each((index, obj) => {
                        $(obj).toggleClass('show-menu')
                        setTimeout(() => {
                            $(obj).toggleClass('make-visible')
                        }, 100)
                    })
                
                })
            })
            $('#menu-toggle .truck').each((index, obj) => {
                $(obj).find('a').first().prepend('<img src="' + baseURL +'/assets/images/truck-blue.svg">')
                $(obj).find('a').first().append('<span class="fas fa-chevron-down"></span>')
                $(obj).find('a').first().on('click', (e) => {
                    e.preventDefault()
                    $(obj).toggleClass('menu-item-open')
                    $(obj).find('.sub-menu').each((index, obj) => {
                        $(obj).toggleClass('show-menu')
                        setTimeout(() => {
                            $(obj).toggleClass('make-visible')
                        }, 100)
                    })
                
                })
            })
            $('#menu-toggle .water').each((index, obj) => {
                $(obj).find('a').first().prepend('<img src="' + baseURL +'/assets/images/water-blue.svg">')
                $(obj).find('a').first().append('<span class="fas fa-chevron-down"></span>')
                $(obj).find('a').first().on('click', (e) => {
                    e.preventDefault()
                    $(obj).toggleClass('menu-item-open')
                    $(obj).find('.sub-menu').each((index, obj) => {
                        $(obj).toggleClass('show-menu')
                        setTimeout(() => {
                            $(obj).toggleClass('make-visible')
                        }, 100)
                    })
                
                })
            })
            $('#menu-toggle .snow').each((index, obj) => {
                $(obj).find('a').first().prepend('<img src="' + baseURL +'/assets/images/snow-blue.svg">')
                $(obj).find('a').first().append('<span class="fas fa-chevron-down"></span>')
                $(obj).find('a').first().on('click', (e) => {
                    e.preventDefault()
                    $(obj).toggleClass('menu-item-open')
                    $(obj).find('.sub-menu').each((index, obj) => {
                        $(obj).toggleClass('show-menu')
                        setTimeout(() => {
                            $(obj).toggleClass('make-visible')
                        }, 100)
                    })
                
                })
            })
            $('#menu-toggle .cargo').each((index, obj) => {
                $(obj).find('a').first().prepend('<img src="' + baseURL +'/assets/images/cargo-blue.svg">')
                $(obj).find('a').first().append('<span class="fas fa-chevron-down"></span>')
                $(obj).find('a').first().on('click', (e) => {
                    e.preventDefault()
                    $(obj).toggleClass('menu-item-open')
                    $(obj).find('.sub-menu').each((index, obj) => {
                        $(obj).toggleClass('show-menu')
                        setTimeout(() => {
                            $(obj).toggleClass('make-visible')
                        }, 100)
                    })
                
                })
            })
            $('#menu-toggle .accessories').each((index, obj) => {
                $(obj).find('a').first().prepend('<img src="' + baseURL +'/assets/images/accessories-blue.svg">')
                $(obj).find('a').first().append('<span class="fas fa-chevron-down"></span>')
                $(obj).find('a').first().on('click', (e) => {
                    e.preventDefault()
                    $(obj).toggleClass('menu-item-open')
                    $(obj).find('.sub-menu').each((index, obj) => {
                        $(obj).toggleClass('show-menu')
                        setTimeout(() => {
                            $(obj).toggleClass('make-visible')
                        }, 100)
                    })
                
                })
            })
            $('#menu-toggle .specs').each((index, obj) => {
                $(obj).find('a').first().prepend('<img src="' + baseURL +'/assets/images/support/doc.svg">')
                $(obj).find('a').first().append('<span class="fas fa-chevron-down"></span>')
                $(obj).find('a').first().on('click', (e) => {
                    e.preventDefault()
                    $(obj).toggleClass('menu-item-open')
                    $(obj).find('.sub-menu').each((index, obj) => {
                        $(obj).toggleClass('show-menu')
                        setTimeout(() => {
                            $(obj).toggleClass('make-visible')
                        }, 100)
                    })
                
                })
            })
            mobileMenuSet = true
        }
    } 
    if ($(window).width() < 1024) mobileMenu()

    function removeMobileMenuContent() {
        $('.menu-item-object-category').find('a img').first().remove()
        $('.menu-item-object-category').find('a span').first().remove()
        $('#menu-item-10298 a, #menu-item-10451 a span').first().remove()
        $('#menu-item-10320 a, #menu-item-10459 a span').first().remove()
    }


    const ro = new ResizeObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.contentRect.width >= 1024) {
                productsSubMenu()
                supportSubMenu()
            } else {
                mobileMenu()
            }
        })
    })
    ro.observe(document.body); // Watch dimension changes on body
})