import globals from './globals'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import $ from 'jquery'

document.addEventListener("DOMContentLoaded", function () {
     const hasSpecs = typeof(document.querySelector('.specs-toggle')) != 'undefined' && document.querySelector('.specs-toggle') != null,
           isProductPage = typeof(document.querySelector('.single-product')) != 'undefined' && document.querySelector('.single-product') != null

     if (hasSpecs && isProductPage) {
        var specsToggle = document.querySelectorAll('.specs-toggle'),
            specsModal = document.querySelector('.specs-modal'),
            keepScrollTarget = document.querySelector('.specs-modal'),
            productNavEl = document.getElementById('nav-product'),
            productHeader = document.getElementById('header-container-product')
            
        if (window.location.href.indexOf('?specs') != -1) {
            productNavEl.classList.add("is-stuck");
            productHeader.classList.add("subnav-is-stuck");
            specsModal.classList.add('showSpecs')
            toggleBackButton(specsModal)
            disableBodyScroll(keepScrollTarget)
        }

        specsToggle.forEach((obj, index) => {
            obj.addEventListener('click', (e) => {
                e.preventDefault()
                e.stopPropagation()
                window.history.replaceState(null, null, "?specs");
                specsModal.classList.add('showSpecs')
                productNavEl.classList.remove("is-stuck");
                productHeader.classList.remove("subnav-is-stuck");
                toggleBackButton(specsModal)
                disableBodyScroll(keepScrollTarget)
            })
        })


        // Set Resources from the Details module
        var specResources = document.querySelector('.spec-resources-container'),
            specResourcesHeading = document.querySelector('.spec-resources .heading-3'),
            resources

            // Hide heading if resources empty
        // if (typeof(document.querySelector('.details-with-icons .detail-links')) == undefined || document.querySelector('.details-with-icons .detail-links') == null) {
        //     if (!specResources.hasChildNodes()) {
        //         specResourcesHeading.classList.add('hide')
        //     }
        // } else {
        //     resources = document.querySelector('.details-with-icons .detail-links')
        //     specResources.innerHTML = resources.innerHTML
        // }
    }

    function toggleBackButton($el) {
        const button = document.querySelector('.back-container button')

        // Set product nav to stay sticky
        globals.isSpecsOpen = true

        button.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            if ($el.classList.contains('showSpecs')) {
                specsModal.classList.remove('showSpecs')
                window.history.replaceState(null, null, window.location.pathname);
                enableBodyScroll(keepScrollTarget)

                // Return product nav to default funcitonality
                globals.isSpecsOpen = false
            }

        })
    }
})