import swiper from 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.min'

const accessorySliders = document.querySelectorAll('.accessories-callout')

accessorySliders.forEach((obj, index) => {
    let spaceBetween = 0

    if (obj.dataset.theme === 'theme-light') {
        spaceBetween =  10
    }

    var accessorySlider = new swiper(obj.querySelector('.overflow-wrapper'), {
        direction: 'horizontal',
        speed: 500,
        spaceBetween: spaceBetween,
        slidesPerView: "3",
        centeredSlides: true,
        loop: true,
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 0
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1,
                spaceBetween: 0
            },
        }
    })

    obj.querySelector('.next').addEventListener('click', (e) => {
        e.preventDefault();
        accessorySlider.slideNext()
    })
    obj.querySelector('.prev').addEventListener('click', (e) => {
        e.preventDefault();
        accessorySlider.slidePrev()
    })
})
