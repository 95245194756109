import $ from 'jquery'

function wheelDistance(e) { 
    if (!e) { 
         e = window.event; 
    } 
     let w = e.wheelDelta, 
         d = e.detail; 
     if (d) { 
         return -d / 3; // Firefox; 
     } 
   
     // IE, Safari, Chrome & other browsers 
     return w / 120; 
 }

 function handleScroll(e) { 
    var delta = wheelDistance(e); 
    console.log(delta); 
    var time = 2100; 
    var distance = 100; 

    $('html, body').stop().animate({ 
        scrollTop: $(window).scrollTop() 
                  - (distance * delta) 
    }, time); 
} 
 
 document.addEventListener("DOMContentLoaded", function () {
     const productDetail = document.querySelector('.product-detail')
     // Adding event listeners for some element in DOM 
    // productDetail.addEventListener("mousewheel", handleScroll); 
    // productDetail.addEventListener("DOMMouseScroll", handleScroll); 
 })