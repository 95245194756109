import $ from 'jquery'
import globals from './globals'


$(document).ready(() => {
    // Product Page
    if (globals.elementInDom('.nav-main')) {
        document.getElementById('nav-main').addEventListener('click', (e) => {
            e.preventDefault()
            window.scroll({
                top: 0, 
                left: 0, 
                behavior: 'smooth' 
            });
        })
    }

    if (globals.elementInDom('.nav-product')) {
        var productNavEl = document.getElementById('nav-product'),
            productHeader = document.getElementById('header-container-product'),
            productDetail = document.querySelector('.product-detail')

            $(function(){
                //the shrinkHeader variable is where you tell the scroll effect to start.
                var shrinkHeader = 70;
                 $(window).scroll(function() {
                   var scroll = getCurrentScroll();
                     if ( scroll >= shrinkHeader ) {
                        productNavEl.classList.add("is-stuck");
                        productHeader.classList.add("subnav-is-stuck");
        
                        if (globals.elementInDom('.product-detail')) {
                            productDetail.classList.add("subnav-is-stuck");
                        }
                       }
                       else {
                        productNavEl.classList.remove("is-stuck");
                        productHeader.classList.remove("subnav-is-stuck");
    
                        if (globals.elementInDom('.product-detail')) {
                            productDetail.classList.remove("subnav-is-stuck");
                        }
                       }
                 });
               function getCurrentScroll() {
                   return window.pageYOffset || document.documentElement.scrollTop;
                   }
               });

        // var observer = new IntersectionObserver(function(entries) {
        //     // no intersection with screen
        //     if(entries[0].intersectionRatio === 0) {
        //         productNavEl.classList.add("is-stuck");
        //         productHeader.classList.add("subnav-is-stuck");

        //         if (globals.elementInDom('.product-detail')) {
        //             productDetail.classList.add("subnav-is-stuck");
        //         }
        //     }
        //     // fully intersects with screen
        //     else if(entries[0].intersectionRatio === 1) {
        //         if (!globals.isSpecsOpen) {
        //             productNavEl.classList.remove("is-stuck");
        //             productHeader.classList.remove("subnav-is-stuck");

        //             if (globals.elementInDom('.product-detail')) {
        //                 productDetail.classList.remove("subnav-is-stuck");
        //             }
        //         }
        //     }
        // }, { threshold: [0,1]});
        // observer.observe(document.querySelector("#nav-product-top"));
    }

    // Category Page
    if (globals.elementInDom('.nav-category')) {
        var categoryNavEl = document.getElementById('nav-category'),
            categoryHeader = document.getElementById('header-container-category')

        var observer = new IntersectionObserver(function(entries) {
            // no intersection with screen
            if(entries[0].intersectionRatio === 0) {
                categoryNavEl.classList.add("is-stuck");
                categoryHeader.classList.add("subnav-is-stuck");
            }
            // fully intersects with screen
            else if(entries[0].intersectionRatio === 1) {
                categoryNavEl.classList.remove("is-stuck");
                categoryHeader.classList.remove("subnav-is-stuck");
            }
        }, { threshold: [0,1]});
        observer.observe(document.querySelector("#nav-category-top"));
    }
})
