import g from './globals'

if (g.elementInDom('.vehicle-model-dropdown') && !g.elementInDom('.use-versions-embed')) {
    const vehicleDropdown = document.querySelector('.vehicle-model-dropdown')
    const vMake = vehicleDropdown.querySelector('.make')
    const vModel = vehicleDropdown.querySelector('.model')
    const vYear = vehicleDropdown.querySelector('.year')
    const vBedLength = vehicleDropdown.querySelector('.bedlength')
    const vbuy = vehicleDropdown.querySelector('.btn-box')
    const vPrice = vehicleDropdown.querySelector('.dropdown-product-price')
    const vehiclesData = vehicleDropdown.getAttribute('data-vehicles')
    const productMappingsData = vehicleDropdown.getAttribute('data-product-mappings')

    // Parse ACF JSON Vehicles data
    const vehicles = JSON.parse(vehiclesData)

    // Parse ACF JSON Product Mappings data
    const productMappings = JSON.parse(productMappingsData)

    // Default product mappings 
    const defaultMappings = productMappings.find((product) => product.result_string == 'default' )

    const getFirstWord = (string => {
        const indexOfSlash = string.indexOf('/');

        if (indexOfSlash !== -1) {
            const extractedSubstring = string.substring(0, indexOfSlash);
            const resultString = extractedSubstring.replace(/-/g, ''); // Remove hyphens

            return resultString.toLowerCase()
        } else {
            return string.toLowerCase()
        }
    })

    const combineWords = (string =>  {
        return string
        .toLowerCase()
        .split(' ')
        .map((word, index) => {
            if (index === 0) {
                return word;
            } else {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
        })
        .join('');
    })

    const replaceSpaceWithUnderscore = (string => {
        return string.replace(/ /g, '_')
    })

    const replaceSlashWithSpace = (string => {
        return string.replace(/\//g, ' ')
    })

    const replaceSlashWithUnderscore = (string => {
        return string.replace(/\//g, '_')
    })

    const removeSpaces = (string => {
        return string.replace(/\s+/g, '');
    })

    const addUnderscoreBeforeWords = (string => {
        const words = string.trim().split(/\s+/);
        return "_" + words.join('_');
    })

    const removeWordsUnlessFirstOrNumber = (string => {
        const words = string.trim().split(/\s+/);
    
        if (words.length > 0) {
            const filteredWords = [words[0]]; // Keep the first word
            
            for (let i = 1; i < words.length; i++) {
                const currentWord = words[i];
                if (!isNaN(currentWord) || currentWord.match(/^\d+$/)) {
                    filteredWords.push(currentWord); // Keep numbers
                }
            }
            
            return filteredWords.join(' ');
        }
        
        return string;
    })

    const filterValue = ((value, type = null) => {
        let string

        switch (type) {
            case 'make':
                string = replaceSlashWithUnderscore(value).toLowerCase()
                return string
            case 'model':
                string = replaceSlashWithSpace(value).toLowerCase()
                string = removeWordsUnlessFirstOrNumber(string)
                string = removeSpaces(string)
                return string
            case 'year': 
                return value.slice(0, 4)
            case 'bed length':
                return combineWords(value)
            default: 
                return getFirstWord(value)
        }
    })

    const updatePrice = ((currentProduct, isDefault = false) => {
        // Set Price
        isDefault || !currentProduct 
        ? vPrice.innerHTML = defaultMappings.price 
        : vPrice.innerHTML = currentProduct.price
    })

    const updateBuyNow = ((isDefault = false) => {
        const make = `${filterValue(vMake.value, 'make')}_`
        const model = `${filterValue(vModel.value, 'model')}_`
        const year = `${filterValue(vYear.value, 'year')}`
        const bedLengthVal = filterValue(vBedLength.value, 'bed length')
        const bedLength = (bedLengthVal) ? `_${bedLengthVal}`: ''

        const resultString = `${make}${model}${year}${bedLength}`

        console.log(resultString)

        const currentProduct = productMappings.find((product) => product.result_string == resultString )
        updatePrice(currentProduct, false)

        // Set Buy Now Link
        isDefault || !currentProduct 
        ? vbuy.href = defaultMappings.link 
        : vbuy.href = currentProduct.link
    })

    const resetSelect = ((select, value) => {
        select.innerHTML = `<option value="" disabled selected>${value}</option>`
    })

    const setSelectVehicles = ((obj) => {
        obj.make.forEach(v => {
            vMake.innerHTML += `<option value="${v.name}">${v.name}</option>`
        })

        vMake.addEventListener('change', (e) => {
            resetSelect(vModel, 'Model')
            resetSelect(vYear, 'Year')
            resetSelect(vBedLength, 'Bed Length')
            
            // Reset to default Buy Now link
            updateBuyNow(true)

            setVehicleModels(obj, e.target.value)
        })
    })

    const setVehicleModels = ((obj, selected) => {
        let modelArray = []

        obj.make.forEach(m => {
            if (m.name == selected) {
                m.models.forEach(model => {
                    modelArray.push(model)
                    vModel.innerHTML += `<option value="${model.name}">${model.name}</option>`
                })
            }
        })

        vModel.addEventListener('change', (e) => {
            resetSelect(vYear, 'Year')
            resetSelect(vBedLength, 'Bed Length')
            updateBuyNow()
            setVehicleYear(modelArray, e.target.value)
        })
    })

    const setVehicleYear = ((obj, selected) => {
        let yearArray = []

        obj.forEach(m => {
            if (m.name == selected) {
                m.years.forEach(y => {
                    yearArray.push(y)
                    vYear.innerHTML += `<option value="${y.year}">${y.year}</option>`
                })
            }
        })

        vYear.addEventListener('change', (e) => {
            resetSelect(vBedLength, 'Bed Length')
            setBedLength(yearArray, e.target.value)
            updateBuyNow()
        })
    })

    const setBedLength = ((obj, selected) => {
        obj.forEach(y => {
            if (y.year == selected) {
                console.log(y)
                y.bed_lengths.forEach(b => {
                    vBedLength.innerHTML += `<option value="${b.bed_length}">${b.bed_length}</option>`
                })
            }
        })

        vBedLength.addEventListener('change', (e) => {
            updateBuyNow()
        })
    })

    // Set Make select fields
    setSelectVehicles(vehicles)

    // Set default price on initial load
    updatePrice(false, true)

    // Set default Link
    updateBuyNow(true)
}