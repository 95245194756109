import {Controller} from 'scrollmagic'
import 'scrollmagic/scrollmagic/minified/plugins/animation.gsap.min'

export default {
    controller: new Controller({refreshInterval: 200}),
    isMenuOpen: false,
    isSpecsOpen: false,
    elementInDom: (selector) => {
        if (document.body.contains(document.querySelector(selector))) {
            return true
        } else {
            return false
        }
    },
}