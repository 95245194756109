import swiper from 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.min'

const featureSliders = document.querySelectorAll('.feature-slider')

featureSliders.forEach((obj, index) => {
    var featureSlider = new swiper(obj.querySelector('.feature-slider-container'), {
        direction: 'horizontal',
        speed: 500,
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 80,
        loop: false
    })

    obj.querySelector('.next').addEventListener('click', (e) => {
        e.preventDefault();
        featureSlider.slideNext()
    })
    obj.querySelector('.prev').addEventListener('click', (e) => {
        e.preventDefault();
        featureSlider.slidePrev()
    })

    // let video = obj.querySelector('.video') // [0]
    // video.play()

})
