import g from './globals'

if (g.elementInDom('.tabular-fit-guide')) {
    const fit_guides = document.querySelectorAll('.fit-guide-products'),
          fit_guide_menu = document.querySelectorAll('.fit-guides-menu .tab')

    fit_guide_menu.forEach((obj, index) => {
        const menu_index = obj.dataset.index
        const tab_menu_active = document.querySelector('.tab-index-' + menu_index)
        const tab_open = document.querySelector('.fit-guide-' + menu_index)

        obj.addEventListener('click', (e) => {
            e.preventDefault();
            new Promise((resolve, reject) => {
                hideAllFitGuides(resolve)
            }).then(() => {
                tab_open.classList.remove('hide')
                tab_menu_active.classList.add('tab-active')
            }).catch((error) => {
                console.error(error);
            })
        })
    })

    function hideAllFitGuides(resolve) {
        fit_guides.forEach(obj => {
            obj.classList.add('hide')
        });

        fit_guide_menu.forEach(obj => {
            obj.classList.remove('tab-active')
        });
        resolve()
    }
}