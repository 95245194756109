import swiper from 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.min'

const product_features = document.querySelectorAll('.product-features')

product_features.forEach((obj, index) => {
    obj.querySelectorAll('.product-features-features-item').forEach((item, index) => {
        let anchors = item.querySelectorAll('a')

        // Cancel click event if an anchor is used
        if (anchors.length) {
            return
        }

        item.addEventListener('click', (e) => {
            e.preventDefault();

            const slideTargetSelector = document.querySelector(e.currentTarget.dataset.slideTargetSelector)
            const slideTargetIndex = e.currentTarget.dataset.slideTargetIndex
            const slideTargetParentSelector = e.currentTarget.dataset.slideTargetSelector.replace(/ .*/,'')
            const swiperInstance = document.querySelector( `${slideTargetParentSelector} .feature-slider-container`).swiper


            new Promise((resolve) => {
                swiperInstance.slideTo(slideTargetIndex)
                setTimeout(() => {
                    resolve()
                }, 550)
            })
                .then(() => {
                    slideTargetSelector.scrollIntoView({behavior: 'smooth', block: 'end'})
                })
                .catch((error) => {
                    console.log(error)
                })
        })
    })
})
