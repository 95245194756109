import swiper from 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.min'

const featureVideo = document.querySelectorAll('.feature-video')

featureVideo.forEach((obj, index) => {
    var videoSlider = new swiper(obj.querySelector('.video-container'), {
        direction: 'horizontal',
        centeredSlides: true,
        spaceBetween: 40,
        loop: true,
        watchOverflow: true,
        navigation: {
            prevEl: obj.querySelector('.prev'),
            nextEl: obj.querySelector('.next')
        },
        on: {
            init: function () {
                if (this.slides.length <= 3) {
                    // First way:
                    this.allowSlidePrev = this.allowSlideNext = false; // disabling swiping
                    obj.querySelector('.prev').setAttribute('hidden', '');  // hiding arrows prev&next
                    obj.querySelector('.next').setAttribute('hidden', '');
    
                    // Second way:
                    this.el.classList.add('swiper-no-swiping');
                }
            }
        }
    })

    // obj.querySelector('.next').addEventListener('click', (e) => {
    //     e.preventDefault();
    //     videoSlider.slideNext()
    // })
    // obj.querySelector('.prev').addEventListener('click', (e) => {
    //     e.preventDefault();
    //     videoSlider.slidePrev()
    // })
})
