import $ from 'jquery'

$('select').each((index, obj) => {

    $(obj).on('change', (e) => {
        e.preventDefault()
        var attr = $(obj).val() === '-None-'

        // For some browsers, `attr` is undefined; for others, `attr` is false. Check for both.
        if (!attr) {
          // Element has this attribute
          $(obj).addClass('selectActive')
        } 
    })
})